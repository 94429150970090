import { Box, Menu, MenuItem, Typography } from '@mui/material';
import React from 'react';
import LinkIcon from '@mui/icons-material/Link';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
type SharedProps = {
  title?: string;
  url?: string;
  textButton?: string;
};
export const ShareLink: React.FC<SharedProps> = ({
  url,
  title,
  textButton,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };
  const animalUrl = `${url}`;
  const handleLinkShare = (): string => {
    navigator.clipboard
      .writeText(animalUrl)
      .then(() => {})
      .catch(() => {});
    return '';
  };

  const handleMailTo = (): string => {
    window.open(`mailto:?subject=${title}&body=${animalUrl}`);
    return '';
  };

  const handleShareTwitter = (): boolean => {
    const twiterUrl = `//twitter.com/intent/tweet?text=${title}&url=${animalUrl}&via=Grupo Editorial Criterio`;
    const options = 'status=1,width=520,height=400,top=auto,left=auto';
    window.open(twiterUrl, 'twitter', options);
    return false;
  };

  const handleWhatsApp = (): string => {
    const linkedlnUrl = `https://api.whatsapp.com/send?text=${animalUrl}`;
    window.open(linkedlnUrl);
    return '';
  };
  const linkedlnShare = (): string => {
    const linkedlnUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${animalUrl}`;
    window.open(linkedlnUrl);
    return '';
  };

  const facebookShare = (): string => {
    const fbUrl = `https://www.facebook.com/sharer/sharer.php?u=${animalUrl}`;
    window.open(fbUrl);
    return '';
  };
  return (
    <>
      <Box onClick={handleMenu}>
        <Typography
          marginRight={1}
          fontSize={18}
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
        >
          {textButton}
        </Typography>
      </Box>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleLinkShare()}>
          <Box display={'flex'}>
            <LinkIcon sx={{ width: 25, height: 25, paddingRight: 1 }} />
            <Typography>Copiar enlace</Typography>
          </Box>
        </MenuItem>
        <MenuItem onClick={() => handleMailTo()}>
          <Box display={'flex'}>
            <EmailIcon sx={{ width: 25, height: 25, paddingRight: 1 }} />
            <Typography>Correo</Typography>
          </Box>
        </MenuItem>
        <MenuItem onClick={() => facebookShare()}>
          <Box display={'flex'}>
            <FacebookIcon sx={{ width: 25, height: 25, paddingRight: 1 }} />
            <Typography>Facebook</Typography>
          </Box>
        </MenuItem>
        <MenuItem onClick={() => linkedlnShare()}>
          <Box display={'flex'}>
            <LinkedInIcon sx={{ width: 25, height: 25, paddingRight: 1 }} />
            <Typography>Linkedin</Typography>
          </Box>
        </MenuItem>
        <MenuItem onClick={() => handleShareTwitter()}>
          <Box display={'flex'}>
            <XIcon sx={{ width: 25, height: 25, paddingRight: 1 }} />
            <Typography>Twitter</Typography>
          </Box>
        </MenuItem>
        <MenuItem onClick={() => handleWhatsApp()}>
          <Box display={'flex'}>
            <WhatsAppIcon sx={{ width: 25, height: 25, paddingRight: 1 }} />
            <Typography>Whatsapp</Typography>
          </Box>
        </MenuItem>
      </Menu>
    </>
  );
};
