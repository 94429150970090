import React from 'react';
import { styled } from '@mui/styles';
import { Button } from '@mui/material';
import AppleLogin from 'react-apple-login';
import { createProvider, getPostUrl } from '../auth-utils';
import { useCookies } from 'react-cookie';
import { Site, useAppleSignupMutation } from '../../../shared/types/generated';
import {
  COOKIES_OPTIONS,
  COOKIES_OPTIONS_CRITERIO,
  USER_COOKIE,
} from '../../../shared/constans';
import { redirectToHome } from '../../../shared/utils';
import { useAnalyticEventCallback } from '../../analytic-event/analytic-event-hooks';
import { openDialogAlert } from '../../alert/alert-actions';

const StyledButton = styled(Button)({
  position: 'relative',
  justifyContent: 'center!important',
  borderRadius: '5px!important',
  height: '38px !important',
  paddingRight: '40px!important',
  border: '1px solid #000000!important',
  backgroundColor: 'white !important',
});

const StyledImage = styled('img')({
  marginRight: 10,
  position: 'relative',
  right: 3,
});

export const AppleButton: React.FC = () => {
  const { analyticsCallback } = useAnalyticEventCallback();
  const [, setCookie] = useCookies([USER_COOKIE]);
  const [appleSignupCallback] = useAppleSignupMutation({
    onCompleted: ({ appleSignup }) => {
      setCookie(USER_COOKIE, appleSignup?.token, COOKIES_OPTIONS);
      setCookie(USER_COOKIE, appleSignup?.token, COOKIES_OPTIONS_CRITERIO);
      createProvider('Apple');
      redirectToHome('/');
    },
    onError: () =>
      openDialogAlert('Tenemos problemas para identificar el usuario apple'),
  });
  return (
    <>
      <AppleLogin
        clientId="mx.vang.suscriptores.client"
        redirectURI="https://miperfil.vanguardia.com.mx/apple/callback"
        callback={(data: {
          authorization: { code?: string; id_token?: string };
        }) => {
          if (data?.authorization && data?.authorization?.id_token) {
            appleSignupCallback({
              variables: {
                token: data?.authorization?.id_token,
                url: getPostUrl(),
                site: Site.AnimalPolitico,
              },
            });
          }
        }} // Catch the response
        scope="name email"
        usePopup
        responseMode="query"
        render={(
          renderProps, //Custom Apple Sign in Button
        ) => (
          <StyledButton
            type="button"
            className=""
            variant="outlined"
            fullWidth
            onClick={() => {
              renderProps.onClick();
              analyticsCallback('apple_clicked');
            }}
            id="apple-event-analytics"
          >
            <StyledImage src="/apple.webp" width={20} height={20} alt="logo" />{' '}
            Continuar con Apple
          </StyledButton>
        )}
      />
    </>
  );
};
