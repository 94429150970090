import React from 'react';
import { Box, Theme, Typography } from '@mui/material';
import { ButtonDefault } from '../../shared/components/ButtonDefault';
import { useNavigate } from 'react-router-dom';
import { createStyles, makeStyles } from '@mui/styles';
import { Header } from '../../shared/components/layout/user/Header';
import { DOMAIN_SITE_URL } from '../../shared/constans';
import { ShareLink } from '../savedNotes/components/ShareLink';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    back: {
      width: 180,
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      background: '#f3f4f6',
    },
    root: {
      flexGrow: 1,
      display: 'flex',
      marginTop: '100px',
      alignItems: 'center',
      justifyContent: 'center',
    },
    body: {},
  }),
);

type ErrorViewProps = {
  text?: string;
};
export const ErrorViewPlans: React.FC<ErrorViewProps> = ({
  text = 'No tienes permisos para acceder a esta página',
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Box className={classes.container}>
      <Header />
      <main className={classes.root}>
        <Box textAlign="center">
          <Box marginY={2}>
            <img
              src="./logoCriterioGrande.png"
              alt="Criterio Grande"
              width={200}
              height={120}
            />
          </Box>
          <Typography variant="h5" fontWeight={800} mb={2}>
            {text}
          </Typography>
          <Box
            display={'flex'}
            marginBottom={2}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <ShareLink
              url={DOMAIN_SITE_URL + '/planes'}
              textButton="Recomienda Animal Político a un amig@"
              title={'Planes de Animal Político'}
            />
          </Box>
          <Box
            display={'flex'}
            component={'div'}
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => navigate('/suscripciones')}
            marginBottom={2}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Typography marginRight={1} fontSize={18}>
              {' '}
              ¿Quieres ver los detalles de tu suscripción?{' '}
            </Typography>
          </Box>
          <ButtonDefault className={classes.back} onClick={() => navigate(-1)}>
            Volver
          </ButtonDefault>
        </Box>
      </main>
    </Box>
  );
};
